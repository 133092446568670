import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faChevronLeft, faXmark, faTrashCan} from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect, CSSProperties } from 'react';
import Modal from 'react-bootstrap/Modal';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import {GetListeAPI, GetFicheApi, DeleteApi, SaveApi, SetDeviceToRetailer} from '../functions/api';
import SPSLoader from './SPSLoader';



function Revendeurs(props) {

  const [stateWindowEdit, setStateWindowEdit] = useState(false);
  const [datas, SetDatas] = useState([]);
  const [theData, SetTheData] = useState([]);
  
  const [uniqueId, setUniqueId] = useState(0);
  const [nom, setNom] = useState("");
  const [adresse, setAdresse] = useState("");
  const [cp, setCP] = useState("");
  const [ville, setVille] = useState("");
  const [id_lora_network, setIdLoraNetwork] = useState("");
  
  const [filterSearch, setFilterSearch] = useState("");

  const [devices, SetDevices] = useState([]);
  const [users, SetUsers] = useState([]);
  const [userData, SetUserData] = useState([]);

  const [searchDevice, setSearchDevice] = useState("");
  const [searchedDevices, setSearchedDevices] = useState([]);

  const [userId, setUserId] = useState(0);
  const [username, setUsername] = useState("");
  const [useremail, setUseremail] = useState("");
  const [userfirstname, setUserFirstname] = useState("");
  const [userlastname, setUserLastname] = useState("");
  const [usertelephone_fixe, setUserTelephoneFixe] = useState("");
  const [usertelephone_mobile, setUserTelephoneMobile] = useState("");
  const [userpassword, setUserPassword] = useState("");
  
  const [stateModalUser, setStateModalUser] = useState(false);

  const [stateModalDevice, setStateModalDevice] = useState(false);

  const [actionAfterSave, setActionAfterSave] = useState("");

  var idtimer = 0;
  let [loading, setLoading] = useState(false);
  
  
  useEffect(() => {
    if(stateWindowEdit == false)
    {
      console.log("recherche des datas");
      GetListeAPI('revendeurs', filterSearch, SetDatas);
    }
    //else
    //  setUpdateNotSaved(false);
    setLoading(false);
  }, [stateWindowEdit]);

  useEffect(() => {
    console.log(JSON.stringify(theData[0]));
    if(theData.length > 0 && theData[0].id != undefined)
    {
      const data = theData[0];
      setLoading(true);
      setUniqueId(data.id);
      setNom(data.nom?data.nom:'');
      setAdresse(data.adresse?data.adresse:"");
      setCP(data.cp?data.cp:"");
      setVille(data.ville?data.ville:"");
      setIdLoraNetwork(data.id_lora_network?data.id_lora_network:"");

      // chargement des utilisateurs
      GetListeAPI("users", "&revendeur_id="+data.id, SetUsers);
      GetListeAPI("devices", "&revendeur_id="+data.id, SetDevices);

      setStateWindowEdit(true);
      setLoading(false);
    }
  }, [theData]);

 

  useEffect(() => {
    GetListeAPI('revendeurs', filterSearch, SetDatas);
  }, [filterSearch]);

  useEffect(() => {
    GetListeAPI('devices', searchDevice+'&availables=true', setSearchedDevices);
  }, [searchDevice]);
  
  
  useEffect(() => {
    if(userData.length > 0 && userData[0].id != undefined)
    {
      const data = userData[0];
      setUserId(data.id);
      setUsername(data.username?data.username:'');
      setUseremail(data.email?data.email:'');
      setUserFirstname(data.first_name?data.first_name:"");
      setUserLastname(data.last_name?data.last_name:"");
      setUserTelephoneFixe(data.telephone_fixe?data.telephone_fixe:"");
      setUserTelephoneMobile(data.telephone_mobile?data.telephone_mobile:"");
      setUserPassword("");
      
      setStateModalUser(true);

    }
  }, [userData]);

  const handleDeleteData = (id) => {
    confirmAlert({
      title: 'Suppression',
      message: 'Souhaitez-vous supprimer ce revendeur ?',
      buttons: [
        {
          label: 'Oui',
          onClick: () => {
            DeleteApi('revendeur', id, afterDelete);
          }
        },
        {
          label: 'Non',
          onClick: () => { 

          }
        }
      ]
    });
  };

  const afterDelete = () => {
    GetListeAPI('revendeurs', filterSearch, SetDatas);
  };

  const handleAdd = (event) => {
    clearFields();
    
    setStateWindowEdit(true);
  };

  const clearFields = () => {
    setUniqueId(0);
    setNom("");
    setAdresse("");
    setCP("");
    setVille("");
    setIdLoraNetwork("");
    
  }

  const handleCancel= (event) => {
    clearFields();
    setStateWindowEdit(false);
  };
  const handleClickEdit = (id) => {
    setLoading(true);
    GetFicheApi('revendeur', id, SetTheData);
  };

  
  const handleSubmit = (event) => {
    event.preventDefault();
    
    saveUser();
  };

  const afterSubmit = () => {
    clearFields();
    switch(actionAfterSave)
    {
      case 'ADD_USER':
        addUser();
        break;
      case 'ADD_DEVICE':
        
        break;
      default:
        setStateWindowEdit(false);
    }
    
  }

  function makeobject()
  {
    const body = {
      id:uniqueId,
      nom: nom,
      adresse: adresse,
      cp: cp,
      ville: ville,
      id_lora_network: id_lora_network,
      
    };
    return body;
  }
    
  const handleBack = (event) => {
    
    props.screenHandling(0);
  };

  function ChargeListeUsers()
  {
    GetListeAPI("users", "&revendeur_id="+uniqueId, SetUsers);
  }

  function ChargeListeDevices()
  {
    GetListeAPI("devices", "&revendeur_id="+uniqueId, SetDevices);
  }

  function saveUser(afterSave)
  {
    setLoading(true);
    const body = makeobject();
    SaveApi ('revendeur', body, afterSubmit);
  }
  
  function clearFieldsUser()
  {
    setUserId(0);
    setUsername('');
    setUseremail('');
    setUserFirstname('');
    setUserLastname('');
    setUserTelephoneFixe('');
    setUserTelephoneMobile('');
    setUserPassword('');
    
  }

  function addUser()
  {
    clearFieldsUser();
    setStateModalUser(true);
    
    
  }

  const handleAddUser = () => {
    if(uniqueId == 0)
      {
        setActionAfterSave('ADD_USER');
        saveUser(afterSubmit);
      }
      else
      {
        addUser();
      }
    
  }

  const handleClickUser = (id) => {
    // recherche de l'utilisateur
    GetFicheApi('user', id, SetUserData);
  }
  const handleDeleteUser = (id) => {
    confirmAlert({
      title: 'Suppression',
      message: 'Souhaitez-vous supprimer cet utilisateur ?',
      buttons: [
        {
          label: 'Oui',
          onClick: () => {
            DeleteApi('user', id,  ChargeListeUsers);
          }
        },
        {
          label: 'Non',
          onClick: () => { 

          }
        }
      ]
    });

    
  }

  const handleClosedUser = () => {
    setStateModalUser(false);
  }

  const handleSubmitUser = () => {
    const body = {
      id: userId,
      revendeur_id: uniqueId,
      username: username,
      email: useremail,
      first_name: userfirstname,
      last_name: userlastname,
      telephone_mobile: usertelephone_mobile,
      telephone_fixe: usertelephone_fixe,
      password: userpassword,
      role: 'RETAILER'
    };
    SaveApi ('user', body, afterSubmitUser);
  }
  const afterSubmitUser = () => {
    
    setStateModalUser(false);
    clearFieldsUser();
    ChargeListeUsers();
  }

  const handleAddDevice = () => {
    // chargement de la liste des capteurs
    GetListeAPI('devices', '&availables=true', setSearchedDevices);
    setStateModalDevice(true);
  }
  const handleChooseDevice = (id) => {
    SetDeviceToRetailer(id, uniqueId, ChargeListeDevices);
  }
  const handleDeleteDevice = (id) => {
    confirmAlert({
      title: 'Suppression',
      message: 'Souhaitez-vous enlever ce capteur à ce revendeur ?',
      buttons: [
        {
          label: 'Oui',
          onClick: () => {
            SetDeviceToRetailer(id, 0, ChargeListeDevices);
          }
        },
        {
          label: 'Non',
          onClick: () => { 

          }
        }
      ]
    });
  }

  const handleClosedDevice = () => {
    setStateModalDevice(false);
  }
  
  return (
    <div className={props.screenVisible?"animscreen visible":"animscreen hidden"} id="administrateurs_screen">
      <div className={stateWindowEdit==false?"liste containerxl":"liste containerxl hidden"} >
          
            <div className="row headScreen">
              <div className="col-3 text-start py-2"><button className="btn btn-link" onClick={handleBack}><FontAwesomeIcon icon={faChevronLeft} /> Retour</button></div>
              <div className="col-6"><h1 className="h4 py-3">Revendeurs</h1></div>
              <div className="col-3 text-end py-2"><button className="btn btn-link" onClick={handleAdd}><FontAwesomeIcon icon={faPlus} /></button></div>
            </div>
          
          <SPSLoader load={loading} />
          <div className="content-screen">
            <div className="container">
              <div className="row">
                <div className="col-md-10 text-center my-2">
                  <input type="text" className="form-control" placeholder='Recherche...' onChange={e => { setFilterSearch(e.target.value); } } value={filterSearch} />
                </div>
                <div className="col-md-2 text-center my-2">
                  {datas.length} enregistrement{datas.length > 1?'s':''}
                </div>
              </div>
            </div>
            <table className="table">
              <tbody>
                <tr>
                  <td className="px-0">
                    {datas.length > 0?
                    <ul className="container liste-data">
                      {datas.map((r) =>(
                      <li className="row mb-2 alternate-color" key={r.id}  >
                          <div className="col-10" onClick={() => handleClickEdit(r.id)}>
                            <strong>{r.nom}</strong><br />
                            {r.adresse} {r.cp} {r.ville}
                          </div>
                          <div className="col-2 text-end col-action">
                              <div className="tools-actions">
                                <button type="button" className="btn btn-danger btn-sm" onClick={() => handleDeleteData(r.id)}><FontAwesomeIcon icon={faTrashCan} /></button>
                              </div>
                          </div>
                      </li>
                      ))}
                    </ul>:<div className="text-muted text-center">Aucun enregistrement</div>
                    }
                    <div className="text-center"><button type="button" className="btn btn-primary" onClick={handleAdd}>Créer un revendeur</button></div>
                    
                  </td>
                </tr>
              </tbody>
            </table>
            
          </div>
            
          
          
    </div>
    <div className={stateWindowEdit?"saisie containerxl":"saisie containerxl hidden"}>
      <div className="row headScreen">
        <div className="col-3 text-start py-2"><button className="btn btn-link" type="button" onClick={handleCancel}><FontAwesomeIcon icon={faChevronLeft} /> Annuler</button></div>
        <div className="col-6"><h1 className="h4 py-3">Revendeur</h1></div>
        <div className="col-3"></div>
      </div>
      <SPSLoader load={loading} />
      <div className="container py-3">
        <form onSubmit={handleSubmit}>
          <div className="row">
          <div className="col-md-6">
              <input type="hidden" name="id" value={uniqueId} />
              <div className="form-group mb-3 row">
                <label className="col-md-2">Nom</label>
                <div className="col-md-10">
                  <input type="text" className="form-control" name="Nom" value={nom} onChange={e => { setNom(e.target.value); }} />
                </div>
              </div>
              <div className="form-group mb-3 row">
                <label className="col-md-2">Adresse</label>
                <div className="col-md-10">
                  <input type="text" className="form-control" name="Adresse" value={adresse} onChange={e => { setAdresse(e.target.value); }} />
                </div>
              </div>
              <div className="form-group mb-3 row">
                <label className="col-md-2">Code Postal</label>
                <div className="col-md-10">
                  <input type="text" className="form-control" name="CP" value={cp} onChange={e => { setCP(e.target.value); }} />
                </div>
              </div>
              <div className="form-group mb-3 row">
                <label className="col-md-2">Ville</label>
                <div className="col-md-10">
                  <input type="text" className="form-control" name="Ville" value={ville} onChange={e => { setVille(e.target.value); }} />
                </div>
              </div>
              
              
              
            </div>
            <div className="col-md-6">
              <fieldset>
                <div className="float-end">
                  <div className="btn-group" role="group" aria-label="Ajout d'utilisateurs">
                    <button type="button" className="btn btn-primary btn-sm" onClick={handleAddUser}>Ajouter</button>
                  </div>
                </div>
                <legend className="float-start d-inline w-auto">Administrateurs</legend>
                
                {users.length > 0?
                <ul className="container liste-data">
                {users.map((r) =>(
                  <li className="row mb-2 alternate-color" key={r.id}  >
                      <div className="col-4" onClick={() => handleClickUser(r.id)}>
                        <strong>{r.first_name} {r.last_name}</strong>
                      </div>
                      <div className="col-6" onClick={() => handleClickUser(r.id)}>
                      {r.email} {r.telephone_fixe} {r.telephone_mobile}
                      </div>
                      <div className="col-2 text-end col-action">
                          <div className="tools-actions">
                            <button type="button" className="btn btn-danger btn-sm" onClick={() => handleDeleteUser(r.id)}><FontAwesomeIcon icon={faTrashCan} /></button>
                          </div>
                      </div>
                  </li>
                  ))}
                  </ul>
                :
                <div className="text-center text-muted">Aucun utilisateur</div>
                }
              </fieldset>
            </div>

            <div className="col-12">
              <fieldset>
                <div className="float-end"><button type="button" className="btn btn-primary btn-sm" onClick={handleAddDevice}>Ajouter</button></div>
                <legend className="float-start d-inline w-auto">Capteurs</legend>
                
                {devices.length > 0?
                <ul className="container liste-data">
                {devices.map((r) =>(
                  <li className="row mb-2 alternate-color" key={r.id}  >
                      <div className="col-4">
                        <strong>{r.crm_device_id}</strong>
                      </div>
                      <div className="col-6">
                      {r.dev_eui} 
                      </div>
                      <div className="col-2 text-end col-action">
                          <div className="tools-actions">
                            <button type="button" className="btn btn-danger btn-sm" onClick={() => handleDeleteDevice(r.id)}>Enlever</button>
                          </div>
                      </div>
                  </li>
                  ))}
                  </ul>
                :
                <div className="text-center text-muted">Aucun capteur</div>
                }
              </fieldset>
            </div>
            
          </div>
          <div className="text-end mt-3 p-3 border-top">
            <button className="btn btn-primary" type="submit">Valider</button>
          </div>
        </form>
        
      </div>
      <Modal show={stateModalUser} onHide={handleClosedUser} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
          <Modal.Title>Utilisateur</Modal.Title>
          </Modal.Header>

          <Modal.Body>
                <form onSubmit={handleSubmitUser}>
                  <div className="form-group mb-3 row">
                    <label className="col-md-3 text-end">Nom utilisateur</label>
                    <div className="col-md-9">
                      <input type="text" className="form-control" name="username" value={username} onChange={e => { setUsername(e.target.value); }} required />
                    </div>
                  </div>
                  <div className="form-group mb-3 row">
                    <label className="col-md-3 text-end">Email</label>
                    <div className="col-md-9">
                      <input type="email" className="form-control" name="email" value={useremail} onChange={e => { setUseremail(e.target.value); }} required />
                    </div>
                  </div>
                  <div className="form-group mb-3 row">
                    <label className="col-md-3 text-end">Prénom</label>
                    <div className="col-md-9">
                      <input type="text" className="form-control" name="first_name" value={userfirstname} onChange={e => { setUserFirstname(e.target.value); }} required />
                    </div>
                  </div>
                  <div className="form-group mb-3 row">
                    <label className="col-md-3 text-end">Nom</label>
                    <div className="col-md-9">
                      <input type="text" className="form-control" name="last_name" value={userlastname} onChange={e => { setUserLastname(e.target.value); }} required />
                    </div>
                  </div>
                  <div className="form-group mb-3 row">
                    <label className="col-md-3 text-end">Mot de passe</label>
                    <div className="col-md-9">
                      <input type="text" className="form-control" name="password" value={userpassword} onChange={e => { setUserPassword(e.target.value); }}  />
                    </div>
                  </div>
                  <div className="form-group mb-3 row">
                    <label className="col-md-3 text-end">Mobile</label>
                    <div className="col-md-4">
                      <input type="text" className="form-control" name="telephone_mobile" value={usertelephone_mobile} onChange={e => { setUserTelephoneMobile(e.target.value); }}  />
                    </div>
                    <label className="col-md-1 text-end">Fixe</label>
                    <div className="col-md-4">
                      <input type="text" className="form-control" name="telephone_mobile" value={usertelephone_fixe} onChange={e => { setUserTelephoneFixe(e.target.value); }}  />
                    </div>
                  </div>
                  
                </form>
          </Modal.Body>

          <Modal.Footer>
              <button type="button" className="btn btn-secondary" onClick={handleClosedUser}>Annuler</button>
              <button type="submit" className="btn btn-primary" onClick={handleSubmitUser}>Enregistrer</button>
          </Modal.Footer>
      </Modal>

      
      <Modal show={stateModalDevice} onHide={handleClosedDevice} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
          <Modal.Title>Capteurs</Modal.Title>
          </Modal.Header>

          <Modal.Body>
                <input className="form-control" type="text" placeholder='Rechercher' value={searchDevice} onChange={e => { setSearchDevice(e.target.value); }}/>
                <table className="table table-stripped" >
                  <thead>
                    <tr>
                      <th>Dev EUI</th>
                      <th>Ref</th>
                      <th>Choisir</th>
                    </tr>
                  </thead>
                  
                  {searchedDevices.length > 0 ?
                    <tbody className="includeList">
                      {searchedDevices.map((r) =>(
                        <tr key={r.id}  >
                            <td>
                              <strong>{r.crm_device_id}</strong>
                            </td>
                            <td>
                              {r.dev_eui} 
                            </td>
                            <td className="text-end col-action">
                                <div className="tools-actions">
                                  <button type="button" className="btn btn-primary btn-sm" onClick={() => handleChooseDevice(r.id)}>Choisir</button>
                                </div>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                  :
                  <tbody>
                    <tr><td colspan="3">Aucun capteur trouvé</td></tr>
                  </tbody>
                  }
                  
                </table>
          </Modal.Body>

          <Modal.Footer>
              <button type="button" className="btn btn-secondary" onClick={handleClosedDevice}>Fermer</button>
          </Modal.Footer>
      </Modal>
    </div>
    </div>
  );

  
}

export default Revendeurs;
